@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.headerAboutUs2{
    width: 100%;
    height: 16vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    color: white;
}

.anchorAboutUs2> img {
    width: 9%;
    margin-left: 4rem;
    transition: .4s;
}

.anchorAboutUs2> img:hover{
    transform:scale(1.2)
}

.SubsPlans{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Ubuntu', sans-serif;
    align-items: center;
    background: url(https://res.cloudinary.com/divor1sn5/image/upload/v1687316638/Bienvenido_Nuevamente_2_gesowd.jpg);
    background-size: cover;
}

.ContainerPlans{
    width: 100vw;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
    gap: 40px;
}

.plansP{
    width: 64%;
    text-align: left;
    font-size: 30px;
    color: #ffffff;
}

.Plan1{
    width: 18%;
    height: 80%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    align-items: center;
    background-color: #ffffff;
    transition: .4s;
    box-shadow: 4px 4px 24px #00000041;
}

.Plan1:hover{
    transform: scale(1.06);
}

.Plan2{
    width: 18%;
    height: 90%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    align-items: center;
    background-color: #ffffff;
    transition: .4s;
    box-shadow: 4px 4px 24px #00000041;
}

.Plan2:hover{
    transform: scale(1.06);
}

.Plan3{
    width: 18%;
    height: 98%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    align-items: center;
    background-color: #ffffff;
    transition: .4s;
    box-shadow: 4px 4px 24px #00000041;
}

.Plan3:hover{
    transform: scale(1.06);
}

.titlePlan{
    width: 90%;
    font-size: 25px;
    margin: 20px;
    font-weight: bold;
    color: #E76F51;
}

.contPricePlan{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    margin: 15px;
}

.signPlan{
    font-weight: bold;
    font-size: 30px;
}

.pricePlan1{
    font-weight: bold;
    font-size: 45px;
}

.pricePlan2{
    font-weight: bold;
    font-size: 30px;
}

.btnPlan{
    width: 65%;
    height: 50px;
    border-radius: 30px;
    background-color: #E76F51;
    border: #E76F51;
    color: #ffffff;
    font-size: 18px;
    margin-top: 30px;
    outline: none;
}

.containPlan{
    width: 90%;
    font-size: 17px;
}