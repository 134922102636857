@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.ContainerAdmin{
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: 'Ubuntu', sans-serif;
    padding-top: 50px;
}

.containerCardAdmin{
    width: 70vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
    
}