@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.NavBar{
    width: 100vw;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content:space-around;
    border-bottom: solid #C4C4C4 3px;
    font-family: 'Ubuntu', sans-serif;
}

.ulHome{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    list-style: none;
}

.anchor{
    color: black;
    font-size: 1.3rem;
    text-decoration: none;
}

.anchor:hover{
    color: #2A9D8F;
    /* font-weight: bold; */
}

.LogoArt{
    width: 34%;
    margin-left: 1.5rem;
    overflow: hidden;
}

.containerButtons{
    height: 100%;
    width: 20%;
    gap: 1.5rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContainerLogo{
    width: 15%;
    /* background-color: #2A9D8F; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
}

.SingUP{
    color: #2A9D8F;
    font-size:1.2rem;
    text-decoration: none;
    width: 40%;
    height: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: #2A9D8F solid 1px;
}

.subP{
    position: absolute; 
    z-index: 1px;
}

.LogIN{
    color: #ffffff;
    background-color: #2A9D8F;
    font-size:1.2rem;
    text-decoration: none;
    width: 45%;
    border-radius: 2rem;
    position: relative;
    height: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    transition: .7s;
}

.LogIN span{
    position: absolute;
    width: 120%;
    height: 100px;
    border-radius: 50%;
    background-color: #1f766c;
    top: -70%;
    left: -120%;
    transition: .7s;
}

.LogIN:hover span{
    left: -1%;
} 

.LogIN:hover{
    color: #ffffff;
}