@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');


.profileE{
    width: 100vw;
    height: 100vh;
    background: url(https://res.cloudinary.com/divor1sn5/image/upload/v1686634665/Bienvenido_Nuevamente_1_bncqo9.jpg);
    background-size: cover;
    font-family: 'Ubuntu', sans-serif;
}

.ContainerProfileE1{
    width: 100vw;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.imgProfileE{
    width: 28%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid 2rem #ffffff;
}

.ContainerProfileE2{
    width: 100vw;
    height: 28%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerE2-1{
    height: 100%;
    width: 30%;
    display: flex;
    justify-content:flex-end;
    align-items: flex-start;
    gap: 15px;
    padding-top: 15px;
}



.containerE2-0{
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    gap: 20px;

}

.containerE2-0  > p{
    font-size: 22px;
}

.btnActuE{
    width: 47%;
    height: 25%;
    font-size: 20px;
    color: #ffffff;
    background-color: #2A9D8F;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: .2s;
}

.btnActuE:hover{
    background-color: #1f766c;
}

.desplegableE{
    width: 11.5%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2A9D8F;
    border-radius: 50px;
    cursor: pointer;
    transition: .2s;
    background-size:contain ;
}

.desplegableE > img{
    width: 35%;
}

.anchorProfileE{
    position: absolute;
    left: 80px;
    top: 35px;
}

.anchorProfileE > img{
    width: 12%;
}


/*                   modal                   */


.Overlay{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.24);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
}

.ContenedorModal{
    width: 500px;
    min-height: 100px;
    background-color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    gap: 40px;
    padding: 50px;
    transition: .3s;
    transition: .3s;
    
}

.form2Sing2 > input{
    margin: 100px;
}

.ContenedorModal > h1{
    font-size: 30px;
}

.BotonCerrar{
    position: absolute;
    top: 30px;
    right: 30px;
    
    width: 30px;
    height: 30px;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
    color: black;
    transition: .3s;
    
}

.btnAct2{
    width: 45%;
    height: 50px;
    font-size: 20px;
    color: #ffffff;
    background-color: #E76F51;
    border: none; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;
    transition: .2s;
}

.BotonCerrar{
    font-weight: bold;
    font-size: 25px;
    color: #E76F51;
}

#password2{
    width: 80%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    padding-bottom: 15px;
    border: none;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 1rem;
    color: #A6A6A6;
}

#emailSing2{
    width: 80%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 1rem;
    color: #A6A6A6;
}

.form2SingE{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#nameUser2{
    width: 80%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 1rem;
    color: #A6A6A6;
}
