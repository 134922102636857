@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.SingUPpage{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Ubuntu', sans-serif;
    box-sizing: border-box;
    background-color: #F4A261;
}

.ContainerSing{
    width: 66%;
    height: 79%;
    background-color: #fff;
    border-radius: 0rem 3rem 3rem 3rem;
    /* padding:1rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 80px 64px #3939391b;
    font-family: 'Ubuntu', sans-serif;
}

.formSing{
    padding-left: 4rem;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.form2Sing{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

#nameUser{
    width: 75%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 0.5rem;
    color: #A6A6A6;
}

#password{
    width: 75%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 0.5rem;
    color: #A6A6A6;
}

#confirmPassword{
    width: 75%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 0.5rem;
    color: #A6A6A6;
}

.container1{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#date{
    width: 26%;
    height: 68%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 0.5rem;
    color: #A6A6A6;
}

#emailSing{
    width: 46%;
    height: 68%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 0.5rem;
    color: #A6A6A6;
}

.headerContactSing{
    margin-bottom: 2rem;
}

.headerContactSing > h3{
    font-size: 3rem;
    font-weight: bold;
    margin: 1rem;
}

.headerContactSing > p{
    font-size: 1rem;
    color: #6b6a6a;
    margin-left: 1rem;
}

.anchorLOG{
    text-decoration: none;
    margin-left: 0.5rem;
    color: #2A9D8F;
}

.anchorLOG:hover{
    font-weight: bold;
}

.containerbutton{
    width: 70%;
    height: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
    margin-left: 1rem;
}

.btnSend{
    width: 70%;
    height: 100%;
    border-radius: 2rem;
    font-size: 1.3rem;
    color: #fff;
    background-color: #E76F51;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    transition: .7s;
}

.btnSend span{
    position: absolute;
    width: 120%;
    height: 130px;
    border-radius: 50%;
    background-color: #ca6247;
    top: -70%;
    left: -120%;
    transition: .7s;
}

.btnSend:hover span{
    left: -1%;
} 

.btnSend:hover{
    color: #ffffff;
}

.p2{
    font-size: 1rem;
    color: #6b6a6a;
    margin: 1rem;
}

.subP{
    position: absolute; 
    z-index: 1px;
}

.sliderSing{
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.subSliderSing{
    width: 90%;
    height: 82%;
    border-radius: 2.5rem;
    overflow: hidden;
    background-color: #FAE1DB;
}

.slidesSing{
    width: 500%;
    height: 500px;
    display: flex;
}

.slidesSing input{
    display: none;
}

.slideSing{
    width: 21.5%;
    transition: 2s;
}

.slideSing img{
    margin-top: 3rem;
    width: 70%;
}

.navigation-manualSing{
    position: absolute;
    width: 590px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.manual-btnSing{
    border: 2px solid #F4A261;
    padding: 60px;
    border-radius:10px;
    cursor: pointer ;
    transition: 1s;
}

.manual-btnSing:not(:last-child){
    margin-right: 20px;
}

.manual-btnSing:hover{
    background-color:#F4A261;
}

.firstSing{
    margin-left:2.5%
}

#radio1Sing:checked ~ .firstSing{
    margin-left:2.5%
}

#radio2Sing:checked ~ .firstSing{
    margin-left:-18.9%;
}

#radio3Sing:checked ~ .firstSing{
    margin-left:-40.4%
}

.navigation-autoSing{
    position:absolute;
    display: flex;
    width: 590px;
    justify-content: center;
    margin-top: 510px;
}

.navigation-autoSing div{
    border: 2px solid #F4A261;
    padding: 0px;
    border-radius:10px;
    transition: 1s;
}

.navigation-autoSing div:not(:last-child){
    margin-right: 20px;
}

#radio1Sing:checked ~ .navigation-autoSing .auto-btn1Sing{
    background-color: #F4A261;
}

#radio2Sing:checked ~ .navigation-autoSing .auto-btn2Sing{
    background-color: #F4A261;
}

#radio3Sing:checked ~ .navigation-autoSing .auto-btn3Sing{
    background-color: #F4A261;
}

.NavUser{
    width: 66%;
    height: 9%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    transition: 1s;
}

.container1User{
    width: 20%;
    height: 83%;
    border-radius: 3rem 3rem 0rem 0rem;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0.5rem;
    transition: 1s;
}

.anchorUser{
    width: 100%;
    height: 100%;
    font-size: 1.9rem;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: #2A9D8F;
    transition: 1s;
}

.userText{
    color:#6b6a6a;
    font-size: 0.9rem;
    font-weight:100;
    transition: 1s;
}

.container2User{
    width: 28%;
    height: 83%;
    border-radius: 3rem 3rem 0rem 0rem;
    background-color: #E4E0E0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-left: 0.5rem;
    padding-bottom: 0.5rem;
    transition: 1s;
}