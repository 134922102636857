@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.MarketPlace{
  width: 100vw;
  display: flex;
}

.containerTotalCards{
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Ubuntu', sans-serif;
  align-items: center;
}

.containerCreateCard{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;
}

.containerProductsMarketPlace{
    width: 78%;
    margin-left: 7rem;
}

.conatinercard2{
    width: 100vw;
    display: flex;
  } 
  
  .containerProducts{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    text-align: center;
}

.imgProduct{
    width: 95%;
    border-radius: 40px;
  }

  .containercard3{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .containerForm1{
    width: 100%;
    height: 30%;
    display: flex;
    gap: 80px;
    justify-content: center;
    align-items: center;
  }

  .containerForm2{
    width: 100%;
    height: 30%;
    display: flex;
    gap: 80px;
    justify-content: center;
    align-items: center;
  }

  #nameUserP1{
    width: 25%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }

  #imgP1{
    width: 25%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }

  #priceP1{
    width: 15%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }

  #cantP1{
    width: 14.5%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }
  #categoryP1{
    width: 25%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }
  #descriptionP1{
    width: 25%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }

  .btnIngr{
    width: 15%;
    height: 50px;
    font-size: 20px;
    color: #ffffff;
    background-color: #E76F51;
    border: none; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;
    transition: .2s;
  }

  .containercard2-0{
    margin-left: 8rem;
    width: 77%;
  }

  .btnDelete{
    position: absolute;
    width: 53px;
    height: 53px;
    border-radius: 50px;
    background-color: #E9C46A;
    border: none;
    font-size: 1.4rem;
    color: #fff;
    margin-top: -350px;
    margin-left: -15px;
    cursor: pointer;
  }

  .btnModalP{
    position:absolute;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: #E9C46A;
    border: none;
    font-size: 1.4rem;
    color: #fff;
    margin-left: 230px;
    margin-bottom: -160px;
    cursor: pointer;
  }

  .nameProduct{
    font-size: 22px;
    margin-top: 12px;
    color: #8D8989;
  }

  .priceProduct{
    font-size: 18px;
    margin: 4px;
    color: #8D8989;
  }

  .description{
    font-size: 18px;
    margin: 2px;
    color: #8D8989;
  }

  .containerCardProducts{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }
  
  .CreateCardUsCli2{
    width: 20%;
  }

  .containerProducts2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btnComprar{
    position: absolute;
    width: 53px;
    height: 53px;
    border-radius: 50px;
    background-color: #E9C46A;
    border: none;
    font-size: 2.1rem;
    color: #fff;
    margin-top: -130px;
    margin-left: 238px;
    cursor: pointer;
  }

  .btnComprar:hover{
    background-color: #caa74c;
  }

  .headerMarket{
    width: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 200px;
  }

  .containerImg{
    width: 6%;
    height: 30%;
    cursor: pointer;
  }

  .containerImg > img{
    width: 100%;
  }
  
  #nameUserP{
    width: 80%;
    font-size: 1.2rem;
    outline: none;
    margin: 15px;
    margin: 15px;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }

  #imgP{
    width: 80%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    margin: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }

  #priceP{
    width: 80%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    margin: 15px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }

  #cantP{
    width: 80%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    margin: 15px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }
  #categoryP{
    width: 80%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    margin: 15px;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }
  #descriptionP{
    width: 80%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    margin: 15px;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    color: #A6A6A6;
  }

  .btnActu3{
    width: 47%;
    height: 50px;
    font-size: 20px;
    color: #ffffff;
    margin: 20px;
    background-color: #2A9D8F;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: .2s;
}

.btnActu3:hover{
    background-color: #1f766c;
}

.ContenedorModalM{
    width: 1000px;
    height: 700px;
    /* min-height: 100px; */
    background-color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 30px;
    gap: 40px;
    padding: 50px;
    transition: .3s;
    transition: .3s;
    
}

.divCard{
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.footerCarrito{
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.containerCreateCardUsCli{
  width: 30%;
}

.containerCarritoProucts{
  width: 100%;
  height: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.btnVaciar{
  width: 30%;
  height: 45%;
  border: none;
  border-radius: 40px;
  font-size: 1.4rem;
  color: #ffffff;
  background-color: #2A9D8F;
  cursor: pointer;
}
.btnVaciar:hover{
  background-color: #1f766c;
}


 