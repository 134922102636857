@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

*{
  padding:0;
  margin:0;
}

body {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #ffffff;
}

.my-sweetalert{
  font-family: 'Ubuntu', sans-serif;
}


