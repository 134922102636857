@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.LogINpage{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: #2A9D8F;
}

.ContainerLog{
    width: 65%;
    height: 80%;
    background-color: #fff;
    border-radius: 3rem;
    padding:1rem;  display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 20px 20px 64px #3939394d;
    font-family: 'Ubuntu', sans-serif;
}

.form{
    padding-left: 4rem;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.form2{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.Logo2{
    width: 17%;
    margin: 1rem;
}

#name{
    width: 70%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 1rem;
    color: #A6A6A6;
}

#email{
    width: 70%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 1rem;
    color: #A6A6A6;
}

.headerContact{
    margin-bottom: 2rem;
}

.headerContact > h3{
    font-size: 3rem;
    font-weight: bold;
    margin: 1rem;
}

.headerContact > p{
    font-size: 1rem;
    color: #6b6a6a;
    margin-left: 1rem;
}

.anchorUP{
    text-decoration: none;
    margin-left: 0.5rem;
    color: #E76F51;
}

.anchorUP:hover{
    font-weight: bold;
}

.containerbuttonSing{
    width: 70%;
    height: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
    margin-left: 1rem;
}

.btnSendSing{
    width: 70%;
    height: 100%;
    border-radius: 2rem;
    font-size: 1.3rem;
    color: #fff;
    background-color: #2A9D8F;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    transition: .7s;
}

.btnSendSing span{
    position: absolute;
    width: 120%;
    height: 130px;
    border-radius: 50%;
    background-color: #258d81;
    top: -70%;
    left: -120%;
    transition: .7s;
}

.btnSendSing:hover span{
    left: -1%;
} 

.btnSendSing:hover{
    color: #ffffff;
}

.p2{
    font-size: 1rem;
    color: #6b6a6a;
    margin: 1rem;
}

.subPSing{
    position: absolute; 
    z-index: 1px;
}

.slider{
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.subSlider{
    width: 90%;
    height: 82%;
    border-radius: 2.5rem;
    overflow: hidden;
    background-color: #CCEDE9;
}

.slides{
    width: 500%;
    height: 500px;
    display: flex;
}

.slides input{
    display: none;
}

.slide{
    width: 21.5%;
    transition: 2s;
}

.slide img{
    margin-top: 3rem;
    width: 70%;
}

.navigation-manual{
    position: absolute;
    width: 590px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.manual-btn{
    border: 2px solid #2A9D8F;
    padding: 6px;
    border-radius:10px;
    cursor: pointer ;
    transition: 1s;
}

.manual-btn:not(:last-child){
    margin-right: 20px;
}

.manual-btn:hover{
    background-color:#2A9D8F;
}

.first{
    margin-left:2.5%
}

#radio1:checked ~ .first{
    margin-left:2.5%
}

#radio2:checked ~ .first{
    margin-left:-18.9%;
}

#radio3:checked ~ .first{
    margin-left:-40.4%
}

.navigation-auto{
    position:absolute;
    display: flex;
    width: 590px;
    justify-content: center;
    margin-top: 510px;
}

.navigation-auto div{
    border: 2px solid #2A9D8F;
    padding: 6px;
    border-radius:10px;
    transition: 1s;
}

.navigation-auto div:not(:last-child){
    margin-right: 20px;
}

#radio1:checked ~ .navigation-auto .auto-btn1{
    background-color: #2A9D8F;
}

#radio2:checked ~ .navigation-auto .auto-btn2{
    background-color: #2A9D8F;
}

#radio3:checked ~ .navigation-auto .auto-btn3{
    background-color: #2A9D8F;
}

.my-sweetalert {
    font-family: 'Ubuntu', sans-serif;
  }