@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.Welcome{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.Left{
    width: 22%;
    height: 100vh;
    /* overflow: hidden; */
}

.Right{
    width: 28%;
    height: 100vh;
}

.Center{
    width: 50%;
    height: 100vh;
    margin-left: 8rem;
}



.NavBarLateral{
    width: 100%;
    height: 54%;
}

.ulHome2{
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 3rem;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.Logo{
    width: 25%;
    margin-left: 6rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.animation{
    width: 90%;
    height: 15%;
    border-radius: 3rem;
    font-family: 'Ubuntu', sans-serif;
    transition: .4s;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem;
}

.animation:hover{
    width: 100%;
    height: 15%;
    border-radius: 3rem;
    font-family: 'Ubuntu', sans-serif;
    background-color: #E76F51;
    transition: .4s;
}

.anchor2{
    width: 100%;
    height: 100%;
    color: #8D8989;
    margin: 3rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    font-family: 'Ubuntu', sans-serif;
    transition: .4s;
}

.anchor2:hover{
    color: #ffffff;
}

.container2{
    width: 100%;
    height: 28%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4rem;
}
.image1{
    width: 85%;
}

.hola{
    font-family: 'Ubuntu', sans-serif;
    color: #8D8989;
    margin-top: 2rem;
    font-size: 1.5rem;
}

.empieza{
    font-family: 'Ubuntu', sans-serif;
    color: #000000;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    
}

.container3{
    width: 88%;
    background: url(https://res.cloudinary.com/divor1sn5/image/upload/v1684105239/Generales/Bienvenido_Nuevamente_ftej7s.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 43%;
    border-radius: 2rem;
}

.image2{
    width: 100%;
}

.more{
    width: 22%;
    height: 12%;
    font-size: 1.4rem;
    color: #ffffff;
    border-radius: 2rem;
    border: none;
    margin-bottom: 5.5rem;
    margin-left: 2.6rem;
    background-color: #E76F51;
    transition: .3s;
}

.more:hover{
    transform: scale(1.1);
}

.productsRecent{
    font-family: 'Ubuntu', sans-serif;
    color: #000000;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size:1.8rem;
}
.container4{
    width: 88%;
    height: 25%;
    display: flex;
    gap: 2.5rem;
}

.container4 div{
    width: 30%;
    height: 100%;
    border-radius: 2rem ;
}

.img1C{
    background: url(https://res.cloudinary.com/divor1sn5/image/upload/v1683916874/Emprendimientos/sweetmarine__/312117927_575648170996112_5474139772880163350_n_jojl6p.jpg);
    background-size: cover;
}

.img2C{
    background: url(https://res.cloudinary.com/divor1sn5/image/upload/v1686711103/Emprendimientos/sweetmarine__/296198233_589450966053486_7373490312191239305_n_hadpg3.jpg);
    background-size: cover;
}
.img3C{
    background: url(https://res.cloudinary.com/divor1sn5/image/upload/v1683916831/Emprendimientos/sweetmarine__/330364886_726892968961386_710696566678699539_n_yc4foo.jpg);
    background-size: cover;
}
.perfil{
    width: 100%;
    height: 15%;
    font-family: 'Ubuntu', sans-serif;
    color: #000000;
    font-size: 1.7rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
}

.imgPerfil{
    width: 9%;
}

.arrowDown{
    width: 5%;
    height: 90%;
}

.myPlan{
    font-family: 'Ubuntu', sans-serif;
    color: #000000;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size:1.8rem;
}

.plan{
    font-family: 'Ubuntu', sans-serif;
    color: #000000;
    font-size:1.4rem;
    height: 30%;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    background: url(https://res.cloudinary.com/divor1sn5/image/upload/v1684163382/Generales/Bienvenido_Nuevamente_ur0scl.png);
    background-size: cover;
}

.type{
    font-family: 'Ubuntu', sans-serif;
    color: #000000;
    font-size:1.8rem;
    font-weight: bold;
    margin-left: 2rem;
}

.time{
    margin-left: 2rem;
    width: 85%;
}

.exp{
    margin-left: 2rem;
    margin-top: 3rem;
}

.notifications{
    font-family: 'Ubuntu', sans-serif;
    color: #000000;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size:1.8rem;
}

.not{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    gap: 3rem;
    font-family: 'Ubuntu', sans-serif;
    color: #807f7f;
    font-size:1.4rem;
}

.navProfile{
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2.5rem;
}


/* menu */
.navProfile ul {
    list-style:none;
    margin:0;
    padding:0;
    transition: .4s;
}

/* items del menu */

.navProfile ul li {
    font-family: 'Ubuntu', sans-serif;
    transition: .4s;
}

/* enlaces del menu */

.navProfile ul a {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    color:#000000;
    text-decoration:none;
    font-size:1.5rem;
    padding:10px;
    font-family: 'Ubuntu', sans-serif;
    transition: .4s;
   }
   
   /* items del menu */
   
   .navProfile ul li {
    position:relative;
    float:bottom;
    margin:0;
    padding:0;
    transition: .4s;
   }
   

   /* menu desplegable */

/* menu desplegable */

.navProfile ul ul {
 display:none;
 position:absolute;
 top:100%;
 left:20px;
 background:#f5f5f5;
 border-radius: 0px 00px 25px 25px;
 padding:0;
 transition: .4s;
}

.anchorProfile:hover{
    background:#e7e7e7;
    border-radius: 0px 00px 20px 20px;
}

/* items del menu desplegable */

.navProfileul ul li {
 float:none;
 width:1600px;
 transition: .4s;
}

.navProfile ul li ul{
    margin-left: 190px;
}

/* enlaces de los items del menu desplegable */

.navProfile ul ul a {
    font-size: 1.2rem;
    line-height:140%;
    padding:12px 50px;
    transition: .4s;
}




/* items del menu desplegable al pasar el ratón */

.navProfile ul li:hover > ul {
 display:block;
 transition: .4s;
} ul ul {
    display:none;
    position:absolute;
    top:100%;
    left:0;
    background:rgb(194, 38, 38);
    padding:0;
    transition: .4s;
   }
