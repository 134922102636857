.Overlay{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.24);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    z-index: 1;
}

.imgModalPencil{
    width: 50%;
}

.ContenedorModal{
    width: 500px;
    min-height: 100px;
    background-color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    gap: 40px;
    padding: 50px;
    transition: .3s;
    transition: .3s;
    
}

.form2Sing2 > input{
    margin: 100px;
}

.ContenedorModal > h1{
    font-size: 30px;
}

.BotonCerrar{
    position: absolute;
    top: 30px;
    right: 30px;
    
    width: 30px;
    height: 30px;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
    color: black;
    transition: .3s;
    
}

.btnAct2{
    width: 45%;
    height: 50px;
    font-size: 20px;
    color: #ffffff;
    background-color: #E76F51;
    border: none; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;
    transition: .2s;
}

.BotonCerrar{
    font-weight: bold;
    font-size: 25px;
    color: #E76F51;
}

#nameUser1{
    width: 80%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 1rem;
    color: #A6A6A6;
}

#password1{
    width: 80%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    padding-bottom: 15px;
    border: none;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 1rem;
    color: #A6A6A6;
}

#emailSing1{
    width: 80%;
    height: 7%;
    font-size: 1.2rem;
    outline: none;
    border: none;
    padding-bottom: 15px;
    border-bottom: solid #A6A6A6 2px;
    font-family: 'Ubuntu', sans-serif;
    margin: 1rem;
    color: #A6A6A6;
}

.form2SingU{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
